<script setup lang="ts" generic="T extends ButtonGroupItem[]">
import type { IconName } from '~/components/ui/Icon.vue';

export type ButtonGroupItem = {
  key?: string;
  icon?: IconName;
  label?: string;
  tooltip?: string;
};

// Props
const value = defineModel<T[number]['key']>();
const props = withDefaults(
  defineProps<{
    size?: 'sm' | 'md' | 'lg';
    items: T;
    side?: 'top' | 'bottom' | 'left' | 'right';
    sideOffset?: number;
    arrow?: boolean;
  }>(),
  {
    size: 'md',
    tooltip: null,
    side: 'top',
    sideOffset: 5,
    arrow: false,
  },
);

const getSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'h-[30px]';
    case 'md':
      return 'h-[32px]';
    case 'lg':
      return 'h-[36px]';
    default:
      return 'h-[32px]';
  }
});
</script>
<template>
  <div class="border border-gray-200 rounded-md flex items-center divide-x divide-gray-200" :class="[getSize]">
    <tooltip-root v-for="(item, index) in items" :key="item.key" class="h-full flex-1">
      <tooltip-trigger class="h-full w-full">
        <button
          :key="item.key"
          type="button"
          class="flex items-center justify-center h-full w-full"
          :class="[
            index === items.length - 1 ? 'rounded-r-md' : '',
            index === 0 ? 'rounded-l-md' : '',
            value === item.key ? 'bg-gray-100' : 'hover:bg-gray-50',
          ]"
          @click="value = item.key"
        >
          <ui-icon v-if="item.icon" :name="item.icon" class="h-4 stroke-gray-600" />
          <p v-if="item.label" class="text-sm font-semibold text-gray-600">{{ item.label }}</p>
        </button>
      </tooltip-trigger>
      <tooltip-portal>
        <tooltip-content
          :side="side"
          class="z-[9999] data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-white select-none rounded-md bg-gray-800/80 px-[15px] py-[10px] text-xs leading-none shadow-sm will-change-[transform,opacity]"
          :side-offset="sideOffset"
        >
          {{ item.tooltip }}
          <tooltip-arrow v-if="arrow" class="fill-gray-800/80" />
        </tooltip-content>
      </tooltip-portal>
    </tooltip-root>
  </div>
</template>
