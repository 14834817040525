<script setup lang="ts">
import { now, getLocalTimeZone, CalendarDate } from '@internationalized/date';
import dayjs from 'dayjs';
import { useField } from 'vee-validate';

// Composables
const { locale } = useI18n();

// Props
const dateValue = defineModel<Date>();
const props = defineProps<{
  name: string;
}>();

// Form
const { setValue } = useField<Date>(props.name, undefined, {
  initialValue: dateValue.value,
  valueProp: dateValue.value,
});

// Computed
const maxDateValue = computed(() => {
  const today = now(getLocalTimeZone());
  return new CalendarDate(today.year, today.month, today.day);
});

// Lifecycle
watch(
  () => dateValue.value,
  (newValue) => setValue(newValue),
);

// Methods
const convertToCalendarDate = (date: Date | null) => {
  if (!date) return null;
  return new CalendarDate(dayjs(date).year(), dayjs(date).month() + 1, dayjs(date).date());
};

const updateDateValue = (date: CalendarDate | null) => {
  if (!date) return;
  dateValue.value = dayjs(`${date.year}-${date.month}-${date.day}`).toDate();
};
</script>
<template>
  <calendar-root
    v-slot="{ weekDays, grid }"
    :locale="locale"
    :model-value="convertToCalendarDate(dateValue)"
    :max-value="maxDateValue"
    class="bg-white p-3"
    fixed-weeks
    @update:model-value="updateDateValue"
  >
    <calendar-header class="flex items-center justify-between">
      <calendar-prev class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center hover:bg-gray-50">
        <ui-icon name="ChevronLeft" class="w-4 h-4" />
      </calendar-prev>
      <calendar-heading
        class="text-gray-900 font-semibold text-sm capitalize select-none cursor-pointer hover:bg-gray-50 rounded-md p-1 px-2"
      />
      <calendar-next class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center hover:bg-gray-50">
        <ui-icon name="ChevronRight" class="w-4 h-4" />
      </calendar-next>
    </calendar-header>
    <div class="flex flex-col pt-2 space-y-4">
      <calendar-grid v-for="month in grid" :key="month.value.toString()" class="w-full border-collapse select-none space-y-1">
        <calendar-grid-head>
          <calendar-grid-row class="grid w-full grid-cols-7">
            <calendar-head-cell v-for="day in weekDays" :key="day" class="rounded-md text-xs text-gray-600">
              {{ day }}
            </calendar-head-cell>
          </calendar-grid-row>
        </calendar-grid-head>
        <calendar-grid-body class="grid">
          <calendar-grid-row v-for="(weekDates, index) in month.rows" :key="`weekDate-${index}`" class="grid grid-cols-7">
            <calendar-cell v-for="weekDate in weekDates" :key="weekDate.toString()" :date="weekDate" class="relative text-center text-sm">
              <calendar-cell-trigger
                :day="weekDate"
                :month="month.value"
                class="relative flex items-center rounded-full data-[disabled]:hover:bg-white data-[disabled]:text-gray-300 data-[highlighted]:bg-green-100 data-[selected]:!bg-primary-500 data-[selected]:!text-white hover:bg-gray-100 justify-center whitespace-nowrap text-sm text-gray-800 w-8 h-8 outline-none before:top-[5px] before:hidden before:rounded-full before:w-1 before:h-1 before:bg-white"
              />
            </calendar-cell>
          </calendar-grid-row>
        </calendar-grid-body>
      </calendar-grid>
    </div>
  </calendar-root>
</template>

<style scoped>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.1s ease-out;
  position: relative;
}

.slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}

.slide-left-enter-from {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}
</style>
