import { mkConfig, generateCsv, download } from 'export-to-csv';

export const coreCSV = () => {
  const csvConfig = mkConfig({ useKeysAsHeaders: true });

  const exportCSV = <T extends { [key: string]: string | number }>(filename?: string, data?: T[]) => {
    csvConfig.filename = 'export_nrjx_' + (filename ?? 'export');
    const csv = generateCsv(csvConfig)(data ?? []);
    download(csvConfig)(csv);
  };

  return {
    exportCSV,
  };
};
