<script setup lang="ts">
// Composables
const { getZoneOptionList } = useExploreFilter();

// Stores
const { max_depth } = storeToRefs(zoneStore());
const { setMaxDepth } = zoneStore();
</script>
<template>
  <div class="grid gap-4">
    <nrjx-input-select
      name="maxDepth"
      fit-to-select
      item-key="key"
      item-label="label"
      :leading-text="$t('explore.filters.depth')"
      :items="getZoneOptionList"
      :model-value="max_depth"
      @update:model-value="setMaxDepth"
    />
  </div>
</template>
