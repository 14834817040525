<script setup lang="ts">
import { useElementBounding } from '@vueuse/core';
import dayjs from 'dayjs';

// Composables
const { formatNumberToIsoNumber } = useNumbers();
const { getAggregationLevelOptionList } = useExploreFilter();
const { updateMultipleAreasToCompare, updateAreaStartPeriod, deleteArea } = useComparisonFilter();
const { getInputColor } = useInput();

// Stores
const { getAreaId, getPeriod, loading } = storeToRefs(exploreStore());
const { aggregationLevel, getComparisonResponse, areasToCompare, zoomPeriod } = storeToRefs(comparisonStore());
const { setAggregationLevel } = comparisonStore();

// Data
const differentStartTimes = ref(false);
const comparisonContainer = ref<HTMLDivElement | null>(null);
const { height } = useElementBounding(comparisonContainer);

// Computed
const compareAreas = computed(() => {
  if (areasToCompare.value.length === 0) return [];
  return areasToCompare.value.map((area) => {
    return {
      ...areaStore().getArea(area.id),
      average_power: formatNumberToIsoNumber(
        getComparisonResponse.value?.compared_to?.find((item) => item.area_id === area.id)?.consumption.avg_power ?? 0,
        1,
      ),
      color: area.color,
    };
  });
});

const getDisabledAreas = computed(() => {
  return [getAreaId.value];
});

// Methods
const addFirstMultipleAreasToCompare = (areaIds: number[]) => {
  const hasDifferentAreas = (newAreaIds: number[]): boolean => {
    const currentAreaIds = areasToCompare.value.map((area) => area.id);
    return JSON.stringify(currentAreaIds.sort()) !== JSON.stringify(newAreaIds.sort());
  };
  if (!hasDifferentAreas(areaIds)) {
    return;
  }
  updateMultipleAreasToCompare({ areaIds, periodStart: getPeriod.value.start });
};

const getDiffBetweenAreaAndReference = (areaId: number) => {
  const reference = getComparisonResponse.value?.reference;
  const area = getComparisonResponse.value?.compared_to.find((item) => item.area_id === areaId);

  const referenceTotal = reference?.total_consumption.quantity ?? 0;
  const areaTotal = area?.consumption.total_consumption.quantity ?? 0;
  if (referenceTotal === 0) return { isPositive: true, value: 0 };

  const percentageDiff = ((areaTotal - referenceTotal) / referenceTotal) * 100;
  const value = Number(Math.abs(percentageDiff).toFixed(2));
  return {
    isPositive: percentageDiff > 0,
    value,
  };
};

const getStartPeriod = (areaId: number) => {
  return dayjs(areasToCompare.value.find((item) => item.id === areaId)?.periodStart).toDate();
};

const updateDifferentStartTimes = (useDifferentStartTimes: boolean) => {
  if (!useDifferentStartTimes) {
    areasToCompare.value.forEach((area) => {
      updateAreaStartPeriod(area.id, zoomPeriod.value?.start ?? getPeriod.value.start);
    });
  }
};
</script>
<template>
  <div ref="comparisonContainer" class="h-[100%]">
    <div class="flex flex-col" :style="{ height: `${height}px` }">
      <div
        :class="compareAreas?.length > 0 ? 'h-[124px]' : 'h-[40px] delay-200'"
        class="flex-shrink-0 pb-4 transition-all duration-300 border-b border-gray-200"
      >
        <div class="flex flex-col items-center justify-between gap-3 transition-all duration-300">
          <nrjx-input-select
            name="aggregateLevel"
            fit-to-select
            item-key="key"
            item-label="label"
            :leading-text="$t('global.show_by')"
            :items="getAggregationLevelOptionList"
            :model-value="aggregationLevel"
            :disabled-message="$t('error.this_aggregation_level_is_not_available_for_this_period')"
            @update:model-value="setAggregationLevel"
          />
          <nrjx-input-area
            v-if="compareAreas?.length > 0"
            side="left"
            :class="compareAreas?.length > 0 ? 'opacity-100 delay-200' : 'opacity-0'"
            class="w-full"
            :area-ids="compareAreas.map((area) => area.id)"
            :area-disabled="getDisabledAreas"
            multiple
            @update:area-ids="addFirstMultipleAreasToCompare"
          >
            <template #button="{ open }">
              <div
                class="w-full px-2.5 h-[32px] text-sm rounded-md bg-white text-gray-600 flex items-center justify-between border border-gray-200"
                :class="getInputColor({ errorMessage: '', disabled: false, open: open })"
              >
                <span class="text-sm text-gray-600">{{ $t('global.area_to_compare') }}: {{ compareAreas.length }}</span>
                <ui-icon name="ChevronDown" class="w-4 h-4 ml-4 stroke-gray-600" />
              </div>
            </template>
          </nrjx-input-area>

          <div
            v-if="compareAreas?.length > 0"
            class="flex items-center justify-between w-full"
            :class="compareAreas?.length > 0 ? 'opacity-100 delay-200' : 'opacity-0'"
          >
            <nrjx-input-switch
              v-model="differentStartTimes"
              name="differentStartTimes"
              :label="$t('comparison.compare_differents_periods')"
              @update:model-value="updateDifferentStartTimes"
            />
          </div>
        </div>
      </div>

      <!-- Liste of the areas to compare -->
      <transition
        enter-active-class="transition delay-100 ease-out duration-300"
        enter-from-class="transform opacity-0 translate-y-2 w-full"
        enter-to-class="transform opacity-100 translate-y-0 w-full"
        leave-active-class="transition-all ease-out duration-200"
        leave-from-class="transform opacity-100"
        leave-to-class="transform opacity-0"
      >
        <div v-if="compareAreas.length > 0" class="flex flex-col overflow-y-auto py-2 gap-2">
          <transition-group
            enter-active-class="transition delay-100 ease-out duration-300"
            enter-from-class="transform opacity-0 translate-y-2 w-full"
            enter-to-class="transform opacity-100 translate-y-0 w-full"
            leave-active-class="transition-all ease-out duration-200"
            leave-from-class="transform opacity-100"
            leave-to-class="transform opacity-0"
          >
            <div
              v-for="compareArea in compareAreas"
              :key="compareArea.id"
              class="p-2 bg-gray-50 border border-gray-200 rounded-md flex flex-col items-start justify-between"
            >
              <div class="flex flex-row items-center justify-between w-full">
                <div class="flex items-center">
                  <ui-badge :color="compareArea.color" intense>{{ compareArea.name }}</ui-badge>

                  <div v-if="!loading" class="flex items-center">
                    <span class="text-xs ml-3 block px-1.5 py-0.5 rounded-md text-gray-600">{{ compareArea.average_power }} kW</span>
                    <span
                      class="text-xs ml-2 block px-1.5 py-0.5 rounded-md"
                      :class="
                        getDiffBetweenAreaAndReference(compareArea.id).isPositive
                          ? 'text-red-500 bg-red-100'
                          : 'text-green-500 bg-green-100'
                      "
                    >
                      {{ getDiffBetweenAreaAndReference(compareArea.id).isPositive ? '+' : '-' }}
                      {{ getDiffBetweenAreaAndReference(compareArea.id).value }}%
                    </span>
                  </div>
                  <ui-skeleton-loader v-else class="w-[70px] h-4 ml-3 rounded-full" />
                </div>
                <div v-if="!loading" class="p-1 hover:bg-gray-50 rounded-md cursor-pointer" @click="deleteArea(compareArea.id)">
                  <ui-icon name="X" class="w-3.5 h-3.5 stroke-gray-600" />
                </div>
              </div>
              <nrjx-input-date
                v-if="differentStartTimes"
                class="w-full mt-2"
                name="period"
                :type="aggregationLevel === 'hour' || aggregationLevel === 'minute' ? 'datetime' : 'date'"
                :model-value="getStartPeriod(compareArea.id)"
                @update:model-value="updateAreaStartPeriod(compareArea.id, $event)"
              />
            </div>
          </transition-group>
        </div>

        <!-- Default message when no area is selected -->
        <div v-else class="flex flex-col items-center justify-center h-full w-full">
          <p class="text-sm text-gray-600 text-center mb-2">{{ $t('comparison.no_areas_selected') }}</p>
          <nrjx-input-area side="left" :area-disabled="getDisabledAreas" multiple @update:area-ids="addFirstMultipleAreasToCompare">
            <template #button>
              <ui-button color="secondary" left-icon="Plus" class="w-full">{{ $t('comparison.add_area') }}</ui-button>
            </template>
          </nrjx-input-area>
        </div>
      </transition>
    </div>
  </div>
</template>
