<script setup lang="ts">
import type { IconName } from '~/components/ui/Icon.vue';

// Components
import ChronologicalFilter from './chronological.vue';
import ByZoneFilter from './zone.vue';
import ComparisonFilter from './comparison.vue';
import dayjs from 'dayjs';
import type { EnergyTypes } from '~/types/new/energy';

// Composables
const route = useRoute();
const { formatNumberToIsoNumber } = useNumbers();
const { getEnergyTypes } = useExploreFilter();
const { getCo2byKWhByMeterId, getCO2Formated } = useCO2();
const { getHeight, getTextSize, getInputColor } = useInput();

// Stores
const { getConsumptionResponse, zoomPeriod: chronologicalZoomPeriod } = storeToRefs(chronologicalStore());
const { getComparisonResponse, zoomPeriod: comparisonZoomPeriod, areasToCompare } = storeToRefs(comparisonStore());
const { getZoneResponse } = storeToRefs(zoneStore());
const { getEnergyType, getPeriod, getAreaId, getMeterId, getChartType } = storeToRefs(exploreStore());
const { setEnergyType, setPeriod, setAreaId, setChartType } = exploreStore();
const { setZoomPeriod: setChronologicalZoomPeriod } = chronologicalStore();
const { removeAllAreaToCompare, setZoomPeriod: setComparisonZoomPeriod } = comparisonStore();

// Computed
const getCo2byKWh = computed(() => getCo2byKWhByMeterId(getMeterId.value));
const comparisonPrincipalAreaConsumption = computed(() => getComparisonResponse.value?.reference.total_consumption.quantity ?? 0);
const comparisonPrincipalAreaCost = computed(() => Number(getComparisonResponse.value?.reference.total_consumption.cost ?? 0));
const zoneConsumption = computed(() => getZoneResponse.value?.total_consumption.quantity ?? 0);
const zoneCost = computed(() => Number(getZoneResponse.value?.total_consumption.cost ?? 0));
const chronologicalConsumption = computed(() => Number(getConsumptionResponse.value?.total_consumption.quantity ?? 0));
const chronologicalCost = computed(() => Number(getConsumptionResponse.value?.total_consumption.cost ?? 0));

const getEnergyTypeIcon = computed(() => {
  switch (getEnergyType.value) {
    case 'Electricity':
      return 'Zap';
    case 'Gas':
      return 'Flame';
    case 'Water':
      return 'Droplets';
    default:
      return 'Atom';
  }
});

const kpis = computed((): { icon: IconName; kpi: string }[] => {
  let cost: string;
  let consumption: string;
  let co2: string;

  if (getChartType.value === 'explore-comparison') {
    cost = formatNumberToIsoNumber(comparisonPrincipalAreaCost.value);
    consumption = formatNumberToIsoNumber(comparisonPrincipalAreaConsumption.value);
    co2 = getCO2Formated(comparisonPrincipalAreaConsumption.value, getCo2byKWh.value);
  } else if (getChartType.value === 'explore-by-zone') {
    cost = formatNumberToIsoNumber(zoneCost.value);
    consumption = formatNumberToIsoNumber(zoneConsumption.value);
    co2 = getCO2Formated(zoneConsumption.value, getCo2byKWh.value);
  } else {
    cost = formatNumberToIsoNumber(chronologicalCost.value);
    consumption = formatNumberToIsoNumber(chronologicalConsumption.value);
    co2 = getCO2Formated(chronologicalConsumption.value, getCo2byKWh.value);
  }

  return [
    {
      icon: 'Gauge',
      kpi: `${consumption} kWh`,
    },
    {
      icon: 'Euro',
      kpi: `${cost} €`,
    },
    {
      icon: 'Earth',
      kpi: co2,
    },
  ];
});

const zoomValue = computed(() => {
  if (getChartType.value === 'explore-comparison') return comparisonZoomPeriod.value;
  if (getChartType.value === 'explore-chronological') return chronologicalZoomPeriod.value;
  return null;
});

// Methods
const setArea = (areaId: number) => {
  if (getChartType.value === 'explore-comparison' && areasToCompare.value.find((area) => area.id === areaId)) {
    removeAllAreaToCompare();
  }
  setAreaId(areaId);
};

const handleEnergyType = async (energyType: EnergyTypes) => {
  if (getEnergyTypes.value.find((type) => type.key === energyType)?.areaToRedirect) {
    setArea(getEnergyTypes.value.find((type) => type.key === energyType)?.areaToRedirect);
    setEnergyType(energyType);
  } else {
    setEnergyType(energyType);
  }
};

const setZoomPeriod = (zoomPeriod: { start: Date; end: Date } | null) => {
  if (getChartType.value === 'explore-comparison') setComparisonZoomPeriod(zoomPeriod);
  if (getChartType.value === 'explore-chronological') setChronologicalZoomPeriod(zoomPeriod);
};

onMounted(() => {
  setChartType(route.name as string);
});
</script>
<template>
  <div class="flex flex-col bg-white border-r border-gray-200">
    <div class="p-4 grid border-b gap-2 border-gray-200">
      <nrjx-input-select
        name="energy-type"
        :model-value="getEnergyType"
        :leading-icon="getEnergyTypeIcon"
        item-key="key"
        item-label="label"
        fit-to-select
        item-secondary-label="secondaryLabel"
        :items="getEnergyTypes ?? []"
        :disabled="getEnergyTypes?.length < 1"
        :content="{ direction: 'bottom', align: 'start', sideOffset: 4 }"
        @update:model-value="handleEnergyType($event as EnergyTypes)"
      />
      <nrjx-input-area
        name="area"
        :area-id="getAreaId"
        :energy-type="getEnergyType"
        class="w-full"
        side="left"
        @update:area-id="setArea($event)"
      />
      <nrjx-input-date-range-with-shortcut
        v-show="!zoomValue"
        name="period"
        leading-icon="Calendar"
        :trailing-icon="null"
        date-format="DD/MM/YYYY"
        :model-value="getPeriod"
        @update:model-value="setPeriod($event)"
      />
      <div
        v-if="zoomValue"
        ref="input"
        class="px-2.5 border rounded-md flex justify-between items-center w-full bg-white"
        :class="[getHeight('md'), getTextSize('md'), getInputColor({ errorMessage: null, disabled: false, open: true })]"
      >
        <div class="flex items-center gap-2">
          <ui-icon name="CalendarDays" class="h-4 w-4 stroke-green-600" />
          <span class="block flex-1 text-gray-600 truncate select-none">
            <span class="text-gray-600 text-xs">{{ $t('explore.filters.zoomed') }} : </span>
            <span class="text-gray-600 text-[10px]">
              {{ dayjs(zoomValue.start).format('DD/MM/YYYY HH:mm') }}
              {{ $t('global.date_to').toLocaleLowerCase() }}
              {{ dayjs(zoomValue.end).format('DD/MM/YYYY HH:mm') }}
            </span>
          </span>
        </div>
        <div class="flex items-center pl-2 cursor-pointer" @click="setZoomPeriod(null)">
          <ui-icon name="X" class="text-gray-600 w-4" :stroke-width="2" />
        </div>
      </div>

      <div
        class="flex flex-col gap-2 justify-between items-center w-full transition-all duration-300 pl-4 border-l"
        :class="zoomValue ? 'border-green-500 mt-2' : 'border-gray-200 mt-4'"
      >
        <div
          v-for="kpi in kpis"
          :key="kpi.icon"
          class="bg-gray-50 border border-gray-200 h-[32px] w-full rounded-md flex items-center justify-start gap-2 px-2"
        >
          <div class="h-6 w-6 flex items-center justify-center bg-primary-100 rounded-md">
            <ui-icon :name="kpi.icon" class="h-4 stroke-primary-500" />
          </div>
          <p class="text-sm font-semibold text-gray-600">{{ kpi.kpi }}</p>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-1 gap-4 p-4">
      <nrjx-button-group
        :model-value="getChartType"
        :items="[
          { key: 'explore-chronological', icon: 'LineChart', tooltip: $t('explore.filters.chart_type.chronological') },
          { key: 'explore-by-zone', icon: 'Network', tooltip: $t('explore.filters.chart_type.by_zone') },
          { key: 'explore-comparison', icon: 'ArrowLeftRight', tooltip: $t('explore.filters.chart_type.comparison') },
        ]"
        arrow
        @update:model-value="
          setChartType($event);
          navigateTo({ name: $event });
        "
      />
      <div :key="$route.path" class="flex flex-col flex-1">
        <chronological-filter v-if="getChartType === 'explore-chronological'" />
        <by-zone-filter v-else-if="getChartType === 'explore-by-zone'" />
        <comparison-filter v-else-if="getChartType === 'explore-comparison'" />
      </div>
    </div>
  </div>
</template>
