<script lang="ts" setup>
// Components
import Filters from '../components/filters/index.vue';

// Composables
const { $posthog } = useNuxtApp();
const dayjs = useDayjs();
const { exportCSV } = coreCSV();

// Store
const { consumptionResponse, aggregationLevel } = storeToRefs(chronologicalStore());
const { period, areaId } = storeToRefs(exploreStore());

// Methods
const exportToCSV = () => {
  const data = consumptionResponse.value?.items;
  if (data) {
    const flattenedData = data.map((item) => ({
      start: item.start,
      end: item.end,
      avg_power: item.avg_power,
      quantity: item.consumption.quantity,
      cost: item.consumption.cost,
    }));

    let areaName = '';
    if (areaId.value) {
      areaName = areaStore().getArea(areaId.value ?? 0)?.name ?? '';
    }
    const filename = `${areaName}_${dayjs(period.value?.start).format('YYYY-MM-DD')}_${dayjs(period.value?.end).format('YYYY-MM-DD')}`;
    exportCSV(filename, flattenedData);
    $posthog.capture('export_csv_from_explore', {
      areaName,
      start: dayjs(period.value?.start).format('YYYY-MM-DD'),
      end: dayjs(period.value?.end).format('YYYY-MM-DD'),
      aggregationLevel: aggregationLevel.value,
    });
  }
};
</script>
<template>
  <div class="flex flex-col h-screen overflow-hidden">
    <ui-header :title="$t('explore.explore')">
      <template #actions>
        <ui-button v-if="$route.path === '/explore/chronological'" left-icon="Download" @click="exportToCSV">
          {{ $t('global.export') }}
        </ui-button>
      </template>
    </ui-header>
    <div class="flex flex-col md:flex-row h-[calc(100vh-64px)] overflow-hidden">
      <filters class="w-full md:w-[320px] flex-shrink-0" />

      <div class="overflow-y-auto p-4 flex-1">
        <transition
          enter-active-class="transition delay-100 ease-out duration-300"
          enter-from-class="transform opacity-0 translate-y-4 w-full"
          enter-to-class="transform opacity-100 translate-y-0 w-full"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="transform opacity-100 translate-y-0 w-full"
          leave-to-class="transform opacity-0 translate-y-4 w-full"
        >
          <div :key="$route.path">
            <nuxt-page />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
