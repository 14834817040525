<script setup lang="ts">
import type { CalendarDate, CalendarDateTime } from '@internationalized/date';
import { parseDate, parseTime } from '@internationalized/date';
import dayjs from 'dayjs';
import { useField } from 'vee-validate';
import type { IconName } from '~/components/ui/Icon.vue';

// Composables
const { getHeight, getTextSize, getInputColor, getSelectOpenAnimation } = useInput();
const { locale } = useI18n();
// Props
const modelValue = defineModel<Date | null>();
const props = withDefaults(
  defineProps<{
    name: string;
    label?: string;
    required?: boolean;
    size?: InputSize;
    leadingIcon?: IconName;
    leadingText?: string;
    trailingIcon?: IconName;
    disabled?: boolean;
    placeholder?: string;
    type?: 'date' | 'datetime';
    content?: {
      direction?: 'left' | 'bottom' | 'right' | 'top';
      align?: 'start' | 'center' | 'end';
      sideOffset?: number;
    };
  }>(),
  {
    label: null,
    leadingIcon: null,
    leadingText: null,
    trailingIcon: 'ChevronDown',
    size: 'md',
    disabled: false,
    placeholder: null,
    type: 'date',
    modelValue: null,
    content: () => ({
      direction: 'bottom',
      align: 'start',
      sideOffset: 4,
    }),
  },
);

// Data
const open = ref(false);
const showTime = ref(false);
const dateFocused = ref(false);
const timeFocused = ref(false);
const { errorMessage, value, setValue } = useField<Date>(props.name, undefined, {
  initialValue: modelValue.value,
  valueProp: modelValue.value,
});

// Methods
const updateDateValue = (date: CalendarDate) => {
  if (!date) return;
  if (date.year < 2015 || date.year > dayjs().year() || dayjs(`${date.year}-${date.month}-${date.day}`).isAfter(dayjs())) return;

  const newDate = dayjs(`${date.year}-${date.month}-${date.day}`);
  modelValue.value = newDate.toDate();
};

const updateTimeValue = (time: CalendarDateTime) => {
  if (!time) return;
  const originalDate = modelValue.value;
  modelValue.value = dayjs(originalDate).set('hour', time.hour).set('minute', time.minute).toDate();
};

const resetTime = () => {
  if (!showTime.value && modelValue.value) {
    modelValue.value = dayjs(modelValue.value).set('hour', 0).set('minute', 0).toDate();
  }
};

// Lifecycle
watch(
  () => modelValue.value,
  (newValue) => setValue(newValue),
);
</script>
<template>
  <div class="text-gray-600 w-full">
    <popover-root v-model:open="open" class="w-full">
      <label v-if="label" :class="errorMessage ? 'text-red-300' : 'text-gray-600'" class="font-medium text-xs mb-[6px] block text-left">
        {{ label }}<span v-if="required" class="text-red-500">*</span>
      </label>
      <popover-trigger class="w-full">
        <slot name="trigger" :open="open">
          <div
            class="px-2.5 border rounded-md flex justify-between items-center w-full bg-white"
            :class="[getHeight(size), getTextSize(size), getInputColor({ errorMessage, disabled, open })]"
          >
            <div class="flex items-center gap-2">
              <ui-icon v-if="leadingIcon" :name="leadingIcon" class="h-4 w-4 stroke-gray-600" />
              <span class="block flex-1 text-gray-600 truncate select-none">
                <span v-if="leadingText" class="text-gray-600">{{ leadingText }} : </span>
                {{
                  value
                    ? dayjs(value).format(type === 'datetime' ? `DD/MM/YYYY ${$t('global.at')} HH:mm` : 'DD/MM/YYYY')
                    : (placeholder ?? $t('global.select_an_item'))
                }}
              </span>
            </div>
            <div class="flex items-center pl-2">
              <ui-icon v-if="trailingIcon" :name="trailingIcon" class="text-gray-600 w-4" :stroke-width="2" />
            </div>
          </div>
        </slot>
      </popover-trigger>
      <popover-portal>
        <popover-content
          position-strategy="fixed"
          :side="content.direction"
          :align="content.align"
          :side-offset="content.sideOffset"
          :class="[getSelectOpenAnimation()]"
          class="bg-white z-50 min-h-[100%] shadow border rounded-md border-gray-200 w-full"
        >
          <div class="flex flex-col p-3 bg-gray-50 gap-2">
            <div class="flex flex-row gap-2">
              <date-field-root
                v-slot="{ segments }"
                :model-value="value ? parseDate(dayjs(value).format('YYYY-MM-DD')) : null"
                :hour-cycle="24"
                :locale="locale"
                class="px-2.5 border rounded-md flex justify-start items-center w-full bg-white text-gray-600"
                :class="[getHeight(size), getTextSize(size), getInputColor({ errorMessage, disabled, open: dateFocused })]"
                @update:model-value="updateDateValue"
              >
                <template v-for="item in segments" :key="item.part">
                  <date-field-input :part="item.part" @focus="dateFocused = true" @blur="dateFocused = false">
                    {{ item.value }}
                  </date-field-input>
                </template>
              </date-field-root>

              <time-field-root
                v-if="showTime"
                v-slot="{ segments }"
                :hour-cycle="24"
                :locale="locale"
                :model-value="modelValue ? parseTime(dayjs(modelValue).format('HH:mm')) : null"
                class="border rounded-md bg-white text-gray-600 flex items-center justify-center px-2 w-[63px]"
                :class="[getHeight(size), getTextSize(size), getInputColor({ errorMessage, disabled, open: timeFocused })]"
                @update:model-value="updateTimeValue($event as CalendarDateTime)"
              >
                <template v-for="item in segments" :key="item.part">
                  <time-field-input v-if="item.part === 'literal'" :part="item.part">
                    {{ item.value }}
                  </time-field-input>
                  <time-field-input
                    v-else
                    :part="item.part"
                    class="rounded p-[1px]"
                    @focus="timeFocused = true"
                    @blur="timeFocused = false"
                  >
                    {{ item.value }}
                  </time-field-input>
                </template>
              </time-field-root>
            </div>

            <nrjx-input-switch
              v-if="type === 'datetime'"
              v-model="showTime"
              name="showTime"
              :label="$t('explore.show_time')"
              @update:model-value="resetTime()"
            />
          </div>
          <nrjx-input-calendar v-model="modelValue" name="date" class="rounded-md" @update:model-value="open = false" />
        </popover-content>
      </popover-portal>
    </popover-root>
  </div>
</template>
