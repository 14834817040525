<script setup lang="ts">
// Composables
const { getAggregationLevelOptionList } = useExploreFilter();

// Stores
const { aggregationLevel } = storeToRefs(chronologicalStore());
const { setAggregateLevel } = chronologicalStore();
</script>
<template>
  <div class="grid gap-4">
    <nrjx-input-select
      name="aggregateLevel"
      fit-to-select
      item-key="key"
      item-label="label"
      :leading-text="$t('global.show_by')"
      :items="getAggregationLevelOptionList"
      :model-value="aggregationLevel"
      :disabled-message="$t('error.this_aggregation_level_is_not_available_for_this_period')"
      @update:model-value="setAggregateLevel"
    />
  </div>
</template>
